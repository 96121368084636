/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { builder } from "@builder.io/react";
import { fetchQueryWithCache } from "../functions-ssr";
import HomeDefault from "../components/home/default";
import HomeBuilder from "../components/home/builder";
import PRODUCTS_AND_CATEGORIES_QUERY from "../queries/product-and-categories";
import TOPMENU_AND_FOOTERMENU_QUERY from "../queries/topMenu-and-footerMenu";
import CAT_MUJER_HOMBRE_QUERY from "../queries/cat-mujer-hombre";

builder.init(`${process.env.NEXT_PUBLIC_BUILDER_API_KEY}`);

export default function Home(props) {

	return (
		props?.setting?.typeDesign === "builder" ?
			<HomeBuilder {...props} /> : <HomeDefault {...props} />
	);
}

export async function getServerSideProps(context) {
	try {
		const userAgent = context.req.headers["user-agent"];
		const isMobile = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
			)
		);

		const data = await fetchQueryWithCache(
			PRODUCTS_AND_CATEGORIES_QUERY,
			{}, // Aquí van las variables si las hay
			"cache_PRODUCTS_AND_CATEGORIES_QUERY",
			14400
		);

		const setting = await fetchQueryWithCache(
			TOPMENU_AND_FOOTERMENU_QUERY,
			{},
			"cache_topmenu_and_footermenu_query",
			84600
		);

		const menu = await fetchQueryWithCache(
			CAT_MUJER_HOMBRE_QUERY,
			{},
			"cache_cat_mujer_hombre_query",
			84600
		);

		let cachedData = {};
		if (setting?.arenbitThemeOptions.typeDesign == "predeterminado") {
			cachedData = {
				isMobile: isMobile,
				mujer: data?.mujer?.nodes ? data.mujer.nodes : [],
				hombre: data?.hombre?.nodes ? data.hombre.nodes : [],
				options: data?.arenbitThemeLanding ? data?.arenbitThemeLanding : [],
				setting: setting?.arenbitThemeOptions ? setting?.arenbitThemeOptions : [],
				seo: setting?.generalSettings ? setting?.generalSettings : [],
				categories: setting?.menus?.nodes[0]?.menuItems?.nodes ? setting?.menus?.nodes[0]?.menuItems?.nodes : [],
				footerMenu: setting?.footerMenu?.nodes[0] ? setting?.footerMenu?.nodes[0] : [],
				menu: menu ? menu : [],
			};
		} else {
			const homepage = await builder
				.get('homepage', {
					userAttributes: {
						// Here, you can target different hero sections
						// to different URL paths. Optionally, add other properties to target
						urlPath: context.resolvedUrl || ''
					}
				})
				.toPromise();

			cachedData = {
				setting: setting?.arenbitThemeOptions ? setting?.arenbitThemeOptions : [],
				seo: setting?.generalSettings ? setting?.generalSettings : [],
				categories: setting?.menus?.nodes[0]?.menuItems?.nodes ? setting?.menus?.nodes[0]?.menuItems?.nodes : [],
				footerMenu: setting?.footerMenu?.nodes[0] ? setting?.footerMenu?.nodes[0] : [],
				menu: menu ? menu : [],
				homepage: homepage || null,
			};
		}

		return {
			props: cachedData,
		};
	} catch (error) {
		console.error(error);
		throw new Error("Error problemas de conexion con el servidor");
	}
}
